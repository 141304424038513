<template>
<div>
  <Loading :isLoading= "isLoading" />
  <div>
    <p style="padding:14px 14px 0px 14px;font-size:20px;color:#810042;font-weight:700;font-family:Lato;">Carga de Documentos</p>
  </div>
  
  <div class="grid grid-cols-1 gap-4 m-5"  >
    <div style="overflow-x:auto;background:#fff;border-width: 1px;border-style: solid;border-color:#85C875;border-radius:7px;box-shadow: 0 0 5px #9ecaed;">
      <br>
      <br>
      <div class="flex" style="margin-top:0px;">
        <div class="w-full">
          <p style="text-align:center;padding-left: 40px;padding-right: 40px;">
              Favor de cargar los documentos faltantes en formato .pdf, .png o .jpeg.
          </p>
        </div>
      </div>
      <DocumentHandler :documents="listDocuments" :key="refresh" v-on:upload-file="uploadFile($event)" v-on:delete-file="deleteFile($event)" />
      <div v-if="listDocuments.response.data.length == 0">
        <center><p style="font-weight: bold;">Cargando documentos...</p></center>
      </div>
      
      <center>
        <div style="margin-bottom:20px;">
          <div class="flex mt-5">
            <div class="w-full">
              <button @click="goBack" class="buttonSave" style="width:200px; height:30px; font-size:18px;">Atrás</button>
            </div>
          </div>
        </div>
      </center>
    </div>
  </div>
</div>
</template>

<script>
import Header from "@/components/Analyst/Header.vue"
import MenuLateral from '@/components/Analyst/MenuLateral.vue'
import Loading from '@/components/Loading/VueLoading.vue'
import "@/assets/css/tailwind.css";

import Swal from "sweetalert2";
import {getTokenDecoden} from "@/helpers/tokenauth";
import {useRouter} from "vue-router";
import moment from "moment";
import {ref} from "vue"
import AdmissionRequest from "@/classes/AdmissionRequest";

export default {
  components:{
    Loading
  },
  setup(){
    const isLoading = ref(false)
    const refresh = ref(0)
    const router = useRouter();
    const admissionId = ref(parseInt(router.currentRoute.value.params.requestId))
    const endpointCcapi = process.env.VUE_APP_CCAPI
    const listDocuments = ref({response:{data:[]}})
    const paymentDate = ref("")
    const admissionObject = ref(new AdmissionRequest())

    let assistedRequest = ref(false)
    let admissionStatus = ref("")
    let clientClassification = ref("")

    getAdmissionRequestById();

    function goBack(){
      let pendingDocumentsList = listDocuments.value.response.data.filter((doc)=> doc.optional == false && doc.document_id == 0)
      let nameDocumentsList = pendingDocumentsList.map((doc)=> doc.document_type).join("<br/>")
      if(pendingDocumentsList.length > 0){
        Swal.fire({
          title: "Aviso",
          html: "Documentos obligatorios pendientes de subir: <br/><br/><ol>"+nameDocumentsList+"</ol><br/>¿Desea continuar?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: '#FEB72B',
          cancelButtonColor: '#FEB72B',
          confirmButtonText: "SI",
          cancelButtonText: "NO"
        })
        .then((result) => {
          if (result.value) {
            router.go(-1)
          }
        });
        return;
      }
      router.go(-1)
    }

    function getRequiredIn(){
      console.log("assisted_request",assistedRequest.value)
      console.log("client_classification",clientClassification.value)
      if(assistedRequest.value){
        if (clientClassification.value == "health_worker")
          return "Inscripción asistida issste"
        else{
          return "Inscripción asistida"
        }
      }else{
        return "solicitud de ingreso fonsnte"
      }
    }

    function deleteFile(value){
      isLoading.value = true;
      admissionObject.value.deleteDocument(sessionStorage.getItem("login"), value.document_id)
      .then(response => {
        getDocumentsAdmissionRequest();
      }).catch( error => {
        Swal.fire({
          title: "Aviso",
          text: "Se presento un error al eliminar el archivo",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        });
        isLoading.value = false;
      })
    }

    function uploadFile(value){
      isLoading.value = true;
      
      let document_list= [
        {
          name: value.newFile.name,
          remote_path: value.newFile.remote_folder,
          extension: value.newFile.extension,
          abbreviation: value.newFile.abbreviation,
          admission_request_id: admissionId.value,
          file: value.newFile.file,
          remote_folder: value.newFile.remote_folder
        }
      ]
      admissionObject.value.uploadDocument(sessionStorage.getItem("login"), document_list)
      .then(response => {
        if(value.newFile.abbreviation == "RPPSOCIAL"){
          paymentDate.value = value.enrollmentFeePaymentDate
          updateEnrollmentFeePaymentDate();
        }
        getDocumentsAdmissionRequest();
      }).catch(error => {
        isLoading.value = false;
        Swal.fire({
          title: "Aviso",
          text: "Error al cargar documento",
          icon: "error",
          confirmButtonColor: '#FEB72B',
        })
      });
    }

    function getDocumentsAdmissionRequest(){
      refresh.value = 0
      isLoading.value = true;
      admissionObject.value.getStructureDocumentsRequired(sessionStorage.getItem("login"), admissionId.value, getRequiredIn(), "documentos_alta_socios")
      .then(response => {
        listDocuments.value = response.data
        console.log("dwdawd",listDocuments.value )
        refresh.value = 1
        isLoading.value = false;
      }).catch(error => {
        isLoading.value = false;
      })
    }

    function getAdmissionRequestById(){  
      isLoading.value = true;    
      admissionObject.value.get(sessionStorage.getItem("login"), admissionId.value)
      .then(response => {
        assistedRequest.value = response.data.response.admission_request.assisted_request
        admissionStatus.value = response.data.response.admission_request.status
        clientClassification.value = response.data.response.admission_request.admission_information.client_classification

        getDocumentsAdmissionRequest();
      }).catch(error => {
        isLoading.value = false;
      })
    }

    function updateEnrollmentFeePaymentDate(){
      isLoading.value = true;
      admissionObject.value.updateEnrollmentFeePaymentDate(sessionStorage.getItem("login"), admissionId.value, paymentDate.value, "transferencia")
      .then(response => {
        paymentDate.value = ""
        this.isLoading = false;
      }).catch( error => {
        this.isLoading = false;
      })
    }

    function getJsonFromLocalStorageBy(item_id) {
      let _json = JSON.parse(localStorage.getItem(item_id));
      return _json;
    }
    
    function setToLocalStorage(item_id, value_item) {
      localStorage.setItem(item_id, JSON.stringify(value_item));
    }


    return { 
      router,
      isLoading,
      listDocuments,
      refresh,
      deleteFile,
      uploadFile,
      assistedRequest,
      admissionStatus,
      clientClassification,
      admissionId,
      goBack
    }
  }
}
</script>

<style scoped>
.register{
  border: 1px solid #999999;
  background-color: red;
  color: #666666;
}
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

#button_popups{
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background-color: #FEB72B;
      width: 250px;
      height: 30px;
      padding-top: 0%;
  }

button {
  outline: none !important;
}

h2 {font-size:36px;margin:0 0 10px 0}
p {margin:0 0 10px 0}
table.width200,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .width200 th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .width200 td,.rwd_auto td {padding:5px;text-align:left}
  .width200 tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .width200 thead tr {border-bottom:1px solid #ccc;color:#726659;font-size:16px !important;font-family:Roboto !important;font-weight: 400 !important;}
  .width200 tbody td {color:#726659;}

@media only screen and (max-width: 1024px)  
{
  table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }
  .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }
  .width200 tr { border-bottom: 1px solid #ccc; }
  .width200 td { position: relative;padding-left: 40%;text-align:left; }
  .width200 td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .width200 td:nth-of-type(1):before { 
    content: "Documento:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(2):before { 
    content: "Estatus:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(3):before { 
    content: "Nombre:"; 
    color: #726659;
    font-family:Roboto;
    font-weight: 700;
  }
  .width200 td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

/* table.subtable,table.rwd_auto {width:100%;margin:0 0 50px 0;}
  .subtable th,.rwd_auto th {background:#fff;padding:5px;text-align:left;}
  .subtable td,.rwd_auto td {padding:5px;text-align:left}
  .subtable tr:last-child td, .rwd_auto tr:last-child td{border:0;}
  .subtable thead tr {border-bottom:1px solid #ccc;}
  .subtable tbody td {color:#8e8e8e;} */

@media only screen and (max-width: 1024px)  
{
  .subtable, .subtable thead, .subtable tbody, .subtable th, .subtable td, .subtable tr { display: block; }
  .subtable thead tr { position: absolute;top: -9999px;left: -9999px; }

  .subtableid td,.subtableid th {
    display: block;
    padding: 1rem;
    text-align:left;
  }
  
  .subtable td {position: relative;padding-left: 40%;text-align:left; }
  .subtable td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtable td:nth-of-type(1):before { content: ""; }
  .subtable td:nth-of-type(2):before { content: ""; }
  .subtable td:nth-of-type(3):before { content: ""; }
  .subtable td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}

@media only screen and (max-width: 1024px)  
{
  .subtableid, .subtableid thead, .subtableid tbody, .subtableid th, .subtableid td, .subtableid tr { display: block; }
  .subtableid thead tr { position: absolute;top: -9999px;left: -9999px; }
  
  .subtableid td {position: relative;padding-left: 40%;text-align:left; }
  .subtableid td:before {  position: absolute; top: 6px; left: 6px; width: 45%; padding-right: 10px; white-space: nowrap;}
  .subtableid td:nth-of-type(1):before { content: ""; }
  .subtableid td:nth-of-type(2):before { content: ""; }
  .subtableid td:nth-of-type(3):before { content: ""; }
  .subtableid td:nth-of-type(4):before { content: ""; }
  .descarto {display:none;}
  .fontsize {font-size:10px;}
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px) 
{
  .descarto {display:none;}
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) 
{
  .descarto {display:none;}
  .fontsize {font-size:10px}
}

.btnMain {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btnMainProofIncomeNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 10px;
  visibility: visible;
}

.btnMainNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right: 15px;
  visibility: visible;
}

.btnDeleteDocument {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:35px;
  width: 12em;
  outline:none;
  float:right;
  margin-right:15px;
}

.btnLoadAfterDocuments {
  text-decoration:none;
  font-family:Roboto;
  font-size: 14px;
  font-weight: bold;
  color:#FEB72B ;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#FEB72B;
  border-width: 1px;
  border-radius:7px;
  height: 40px;
  width: 20em;
  outline:none;
}

.btnAddDocuments {
  text-decoration:none;
  font-family:Roboto;
  font-size: 14px;
  font-weight: bold;
  color:#FFF ;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FEB72B;
  border: 0px solid;
  border-color:#FEB72B;
  border-width: 1px;
  border-radius:7px;
  height:40px;
  width: 20em;
  outline:none;
}

@media (max-width: 500px) {
  .btnLoadAfterDocuments {
    height: 50px !important;
  }
  .btnAddDocuments {
    height: 50px !important;
  }
}

.btnFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  outline:none;
}

.btnFile + label {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color:#8e8e8e;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:#8e8e8e;
  border-width: 1px;border-radius:35px;
  width: 12em;
  text-align: center;
}

.btnFile + label {
	cursor: pointer; /* "hand" cursor */
}

.table-scroll{
  width:100%;
  /* display: block; */
  empty-cells: show;
  /* Decoration */
  border-spacing: 0;
}

.table-scroll thead{
  position:relative;
  display: block;
  width:100%;
  overflow-y: scroll;
}

.table-scroll tbody{
  /* Position */
  display: block; position:relative;
  width:100%; overflow-y:scroll;
  /* Decoration */
}

.table-scroll tr{
  width: 100%;
  display:flex;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #8E8E8E; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8E8E8E; 
}

.table-scroll td,.table-scroll th{
  /* flex-basis:100%; */
  display: block;
  padding: 1rem;
  text-align:left;
}

.table-scroll2 td,.table-scroll2 th{
  display: block;
  padding: 1rem;
  text-align:left;
}
/* Other options */

.table-scroll.small-first-col td:first-child,
.table-scroll.small-first-col th:first-child{
  flex-basis:20%;
  flex-grow:1;
}

.table-scroll tbody tr:nth-child(2n){
  
}

.body-half-screen{
  max-height: 20vh;
}

.small-col{}

.titlesProofIncome {
  word-wrap: break-word;
}

.titles {
  font-size:13px;
  font-family:Roboto;
  font-weight: 400;
  word-wrap: break-word;
}

.tdTable {
  height: 40px;
  padding-left: 0 !important;
}

.trTable {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  vertical-align:top;
  height:100px;
}
.trRFC {
  height:100px;
  vertical-align: middle;
  border-bottom: 1px solid #ccc;
}
.btnDeleteResponsive {
  visibility: hidden;
  float:left;
}

.btnDeleteNoResponsive {
  text-decoration:none;
  font-family:Roboto;
  font-size: 13px;
  color: red;
  padding-top:5px;
  padding-bottom:5px;
  background-color:#FFF;
  border: 0px solid;
  border-color:red;
  border-width: 1px;
  border-radius:35px;
  width: 12em;
  outline:none;
  visibility: visible;
  float:right;
}
.btnDeleteMobile {
  visibility: hidden;
  float:left;
}
.btnLoadMobile {
  visibility: hidden;
  float:left;
}
.textMati {
  color:#6ee5d3;
  font-weight:bold;
  text-align:center;
  float:right;
  margin-right:23px;
}
.tdButton {
  display:block;
}
@media only screen and (max-width: 1024px)  
{
  .trTable {
    height: 400px !important;
  }
  .trRFC {
    height:auto;
    vertical-align: middle;
    border-bottom: 1px solid #ccc;
  }
  .tdTable {
    height: auto;
    padding-left: 0px;
  }
  .btnDeleteResponsive {
    visibility: visible;
  }
  .btnDeleteNoResponsive {
    visibility: hidden;
  }
  .btnDeleteMobile {
    visibility: visible;
  }
  .btnLoadMobile {
    visibility: visible;
  }
  .btnMainNoResponsive {
    visibility: hidden;
  }
  .btnMainProofIncomeNoResponsive {
    visibility: hidden;
  }
  .body-half-screen{
    max-height: 30vh;
  }
  .btnDeleteResponsive {
    width:70px;
  }
  .btnMainProofIncomeNoResponsive {
    width:70px;
    float:none;
    margin-right: 0;
  }
  .btnMainNoResponsive{
    width:70px;
    float:none;
    margin-right: 0;
  }
  .btnDeleteDocument {
    width: 5em;
    float: none;
    margin-right: 0;
  }
  .titlesProofIncome {
    width: 300px;
  }
  .titles{
    width: 300px;
  }
  .textMati {
    text-align:left;
    float:none;
    margin-right:0;
  }
  .checkmark {
    margin: 0 !important;
  }
  .tdButton {
    display:none !important;
  }
}

@media only screen and (max-width: 630px)  
{
  .titlesProofIncome {
    width: 100px; 
  }
  .titles{
    width: 200px;
  }
}

@media only screen and (max-width: 630px)  
{
  .titlesProofIncome {
    width: 100px; 
  }
  .titles{
    width: 100px;
  }
}

#progress_bar {
  margin: 10px 0;
  padding: 3px;
  border: 1px solid #000;
  font-size: 14px;
  clear: both;
  opacity: 0;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -webkit-transition: opacity 1s linear;
}
#progress_bar.loading {
  opacity: 1.0;
}
#progress_bar .percent {
  background-color: #99ccff;
  height: auto;
  width: 0;
}

@media (max-width: 501px) {

  .next_button, .end_process, .back_button {
      width: 100% !important;
  }
}

.buttonSave {
    background-color: #4CAF50; /* Green */
    opacity: 0.7;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 1px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    font-family:Lato;
    widows: 10px;
    height: 30px;
    cursor: pointer;
}
</style>